
var tf = tf || {};

(function(app, $) {

    app.classes = {
        current:    'current',
        hidden:     'is-hidden',
        transition: 'has-transition',
        height0 :   'height-zero'
    };

    app.namespace = 'tf';

    app.events = {
        click: 'click.' + app.namespace
    };

    app.init = function() {
        app.bindPlugins();
        app.swiperInit();
        app.searchToggle();
        app.range();
        app.dateFix();
        app.disableLink();
        app.fullPageIndex();
        app.fileInput();
        app.scrolledImage();
        app.menuFixed();

        function GetIEVersion() {
            var sAgent = window.navigator.userAgent;
            var Idx = sAgent.indexOf("MSIE");

            // If IE, return version number.
            if (Idx > 0)
                return parseInt(sAgent.substring(Idx+ 5, sAgent.indexOf(".", Idx)));

            // If IE 11 then look for Updated user agent string.
            else if (!!navigator.userAgent.match(/Trident\/7\./))
                return 11;

            else
                return 0; //It is not IE
        }

        if (GetIEVersion() > 0)
            $('.page-wrap').addClass('ie');

        $('.file-input').on('change',function () {
            $('.file-text').val(this.value);
        });

        $('.file-delete-btn').on('click',function (e) {
            e.preventDefault();
            $('.file-text').val('');
            $('.file-input').val('');
        });

        $('.tab-view .tab-view-buttons').each(function(b,buttons){
            var buttonsWidth = ($(buttons).width()-120)/3;

            $(buttons).find('a').each(function(t,tab){
                var tabWidth = $(tab).width();

                if(tabWidth > buttonsWidth){
                    $(tab).css('width','auto');
                } else {
                    $(tab).css('width','100%');
                }
            })
        });

        var menuLink = $('.scroll-link a');
        menuLink.on('click', function(e){

            var _this = $(this);

            if($('body').width() < 950){
                $('.page-wrap').removeClass('menu-open');
                linkScroll(_this.attr('href'));
            } else {
                linkScroll(_this.attr('href'));
            }
        });

        function linkScroll(link) {
            var pos= $(link).position();
            if(!$('body').width() < 950) {
                var posTop = pos.top - 60;
            } else {
                var posTop = pos.top - 100;
            }

            $('html, body').stop().animate({
                'scrollTop': posTop
            }, 500);
        }

        $('.language-menu > li a').on('click',function (e) {
            $('.language-menu .sub-menu').show();
        });

        $(".language-menu").click(function(e) {
            e.stopPropagation();
        });

        $(document).click(function() {
            $(".language-menu .sub-menu").hide();
            $('.flip-container').removeClass('hover');
        });

        $('.mobile-menu-icon').on('click',function () {
            $(window).scrollTop(0);
            $('.page-wrap').toggleClass('menu-open');
        });

        $('.ie .flip-container').bind('touchstart', function(){
            $(this).addClass('hover');
        }).bind('touchend', function(){
            $(this).removeClass('hover');
        });

        $('.flip-container').on('click', function (e) {
            if(window.innerWidth < 1024) {
                $('.flip-container').removeClass('hover');
                $(this).toggleClass('hover');
                e.stopPropagation();
            }
        });

        var langImg = $('.language-menu > .menu-item > a img');
        if(langImg.attr('title') == "Türkçe"){
            $(langImg).parent().addClass('tr-flag');
        } else if(langImg.attr('title') == "English"){
            $(langImg).parent().addClass('en-flag');
        }

    };

    app.modules = [];

    app.registerInit = function(module) {
        app.modules.push(module);
    };

    app.plugged = [
        {func: 'collapsible', target: '.collapsible'},
        {func: 'select', target: '.select-ur'},
        {func: 'tabview', target: '.tab-view'}
    ];

    app.initModules = function() {
        if(app.modules.length > 0) {
            app.modules.forEach(function(module) {
                if(typeof app[module] !== 'undefined' && !app[module].isInitialized()) {
                    app[module].init();
                }
            });
        }
    };

    $(".modal").uxrmodal({
        allowMultiple: true,
        onLoad       : function() {
            $('.uxr-modal-container').find('video')[0].play();
        }
    });


    app.bindPlugins = function() {
        $(app.plugged).each(function() {
            var e = this,
                p;

            if(typeof $.fn[e.func] !== 'function') {
                return;
            }

            if(typeof e.params !== 'undefined') {
                p = e.params;
            }

            $(e.target)[e.func](p);
        });
    };

    var windowSize = function () {
      return $(window).width();
    };

    app.swiperInit = function() {

        app.swiperInit.selectors =  [
            {
                swiper : '.home-slider .swiper-container' ,
                options: [
                    {
                        pagination: {
                            el: '.home-slider .swiper-pagination',
                            clickable: true
                        },
                        spaceBetween: 30,
                        autoplay: 6000,
                        paginationClickable : true
                    }
                ]
            },
            {
                swiper : '.news-slider .swiper-container' ,
                options: [
                    {
                        pagination: {
                            el: '.news-pagination',
                            clickable: true
                        },
                        spaceBetween: 30,
                        autoplay: 6000,
                        paginationClickable : true
                    }
                ]
            },
            {
                swiper : '.event-slider .swiper-container' ,
                options: [
                    {
                        pagination: {
                            el: '.event-pagination',
                            clickable: true
                        },
                        spaceBetween: 30,
                        autoplay: 6000,
                        paginationClickable : true
                    }
                ]
            },
            {
                swiper : '.products .swiper-container' ,
                options: [
                    {
                        pagination: {
                            el: '.products .swiper-pagination',
                            clickable: true
                        },
                        navigation: {
                            nextEl: '.products .swiper-button-next',
                            prevEl: '.products .swiper-button-prev'
                        },
                        spaceBetween: 20,
                        autoplay: 6000,
                        paginationClickable : true,
                        slidesPerView:4,
                        breakpoints: {
                            1000: {
                                slidesPerView: 3,
                                spaceBetween: 10,
                                centeredSlides: true
                            },
                            767: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                                centeredSlides: true
                            }
                        }
                    }
                ]
            },
            {
                swiper : '.news .swiper-container' ,
                options: [
                    {
                        pagination: {
                            el: '.news .swiper-pagination',
                            clickable: true
                        },
                        navigation: {
                            nextEl: '.news .swiper-button-next',
                            prevEl: '.news .swiper-button-prev'
                        },
                        spaceBetween: 20,
                        autoplay: 6000,
                        paginationClickable : true,
                        slidesPerView:3,
                        breakpoints: {
                            1000: {
                                slidesPerView: 3,
                                spaceBetween: 10,
                                centeredSlides: true
                            },
                            767: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                                centeredSlides: true
                            }
                        }
                    }
                ]
            },
        ];

        $.each(app.swiperInit.selectors, function (index, value) {
           var opts = {};
           $.each(value.options, function(i,v){
               opts = v;
           });
           index = new Swiper(value.swiper , opts);
        });
    };


    app.menuFixed = function() {

        if($(window).scrollTop()>0)
        {
            $('.header').addClass('fixed');
        }
        $(window).scroll(function(e){

            if($(window).scrollTop()>0)
            {
                $('.header').addClass('fixed');
            }
            else
            {
                $('.header').removeClass('fixed');
            }
        });
    };

    app.searchToggle = function() {

        app.searchToggle.selectors = {
            searchOpen : '.search-toggle',
            searchClose: '.search-box-close',
            searchBox  : '#search-box'
        };

        $(app.searchToggle.selectors.searchOpen).on(app.events.click, function(e){
            e.preventDefault();
            searchOpen();
        });

        $(app.searchToggle.selectors.searchClose).on(app.events.click, function (e) {
           e.preventDefault();
           searchClose();
        });

        var searchOpen = function(){
            $(app.searchToggle.selectors.searchBox).removeClass(app.classes.height0);
            $(app.searchToggle.selectors.searchOpen).addClass(app.classes.hidden);
        };

        var searchClose = function(){
            $(app.searchToggle.selectors.searchBox).addClass(app.classes.height0);
            $(app.searchToggle.selectors.searchOpen).removeClass(app.classes.hidden);
        };

    };

    app.range = function() {

        app.range.selectors =  {
            rangeInput : '.range',
            rangeOut : '.range-output .amount'
        };

        $(window).on('load', function () {
           rangeInit();
        });

        $(app.range.selectors.rangeInput).on('input change', function(){
            rangeChange(this);
        });

        var rangeInit = function() {
            $('.range').each(function () {
                var type= $(this).data('range');
                var min = $(this).attr('min');
                var template = '<output class="range-output">  <p class="range-output-info">  <span class="range-output-value amount" >' + min + '</span> <span class="range-output-value small">' + type +'</span> </p> </output>';
                $(this).parent().append(template);
                $(this).next().css('left', -30 + 'px');
                $(this).attr('value', min);
            });
        };

        var rangeChange = function (el) {

          var value = $(el).val(),
              max = $(el).attr('max'),
              min = $(el).attr('min'),
              diff= max - min,
              thumbwidth = $(el).data('thumbwidth'),
              outputWidth = $(el).parent().find('.range-output').width()/2,
              left,
              offset;

                left = ((value-min)/diff) * 100;
                offset = Math.round(thumbwidth * left /100) - (thumbwidth/2);
                offset+=outputWidth;

          $(el).next().css('left',  'calc(' + left + '% - ' + offset + 'px)');
          $(el).next().find('.amount').text(value);
        };

    };

    app.dateFix = function() {
        var findMonthName = function(month) {
            var monthName;
            switch(month) {
                case '01':
                    monthName = 'Ocak';
                    break;
                case '02':
                    monthName =  'Şubat';
                    break;
                case '03':
                    monthName =  'Mart';
                    break;
                case '04':
                    monthName =  'Nisan';
                    break;
                case '05':
                    monthName =  'Mayıs';
                    break;
                case '06':
                    monthName =  'Haziran';
                    break;
                case '07':
                    monthName =  'Temmuz';
                    break;
                case '08':
                    monthName =  'Ağustos';
                    break;
                case '09':
                    monthName =  'Eylül';
                    break;
                case '10':
                    monthName =  'Ekim';
                    break;
                case '11':
                    monthName =  'Kasım';
                    break;
                case '12':
                    monthName =  'Aralık';
                    break;
                default:
                    monthName =  'Ocak';
            }

            return monthName;
        };

        var newDate = function() {
            var fixDates  = document.querySelectorAll('.fix-date');
            for(var index=0; index < fixDates.length; index++ ) {
                if(fixDates[index].innerHTML.trim().length===8) {
                    var yy,mm,dd,newDate, date;
                    date = fixDates[index].innerHTML.trim();
                    yy = date.substring(0,4);
                    mm = date.substring(4,6);
                    dd = date.substring(6,9);
                    newDate = dd + ' ' + findMonthName(mm) + ' ' + yy;
                    fixDates[index].innerHTML= newDate;
                }
            }
        };
        newDate();
    };


    app.disableLink = function () {
            var disabled = $('.kapali-link > a');
            disabled.on('click', function (e) {
                e.preventDefault();
            });
    } ;

    app.fullPageIndex = function () {
        var height, width, newHeight, body, imgSlider;
        body = $('body');
        imgSlider = $('.main-slider-image');
        width = body.width();

        var setNewHeight = function () {
            height = body.height();
            newHeight = height-357;
            imgSlider.css('max-height' , newHeight +'px');
            $('.main-slider .swiper-slide').css('height' , newHeight +'px');
        };

        if(width > 1024) {
            setNewHeight();
        }

        $(window).resize(function () {
            if(body.width()>=1024) {
                setNewHeight();
            }
        });
    };

    app.fileInput = function () {
       var fileInputLabel  = $('.form-is-file'),
           inputFile       = $('.form-input-file'),
           fileClear       = $('.file-clear');

       fileClear.addClass(app.classes.hidden);

       var createTempalate = function (value) {
           return '<span class="file-input-label">' + value + ' </span>';
       };

       fileInputLabel.append(createTempalate('Seçiniz'));

       inputFile.on('change', function () {
           var newPath = $(this).val();
           newPath = newPath.match(/[^\/\\]+$/);
           $(this).parent().parent().find('.file-input-label').text(newPath);
           $(this).parent().parent().find('.file-input-label').addClass('has-file');
           $(this).parent().parent().find(fileClear).removeClass(app.classes.hidden);
       });


       fileClear.on('click', function (e) {
           e.preventDefault();
           var fileInput = $(this).parent().find(inputFile);
           var fileInputLabel =  $(this).parent().find('.file-input-label');
           fileInput.val('');
           fileInputLabel.text('Seçiniz');
           $(this).addClass(app.classes.hidden);
           $(this).parent().find('.file-input-label').removeClass('has-file');
       });

    };

    app.scrolledImage = function () {
       var mouseBtn = $('.scroll-btn');

       mouseBtn.on('click', function () {
           var _this = $(this);
           _this.addClass(app.classes.hidden);
       });
    };


})(tf, jQuery);

(function ($) {
    $(function() {
        tf.init();
    });
})(jQuery);
